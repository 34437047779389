
import {get,post} from "@/axios";

//订单模块
export function getOrderList(data) {
    return post('/api/transactions/list',data);
}

export function getOrdersTotalStatistics(data) {
    return get('/api/transactions/getOrdersTotalStatistics',data);
}

