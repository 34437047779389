<template>
  <div>
    <div class="mainContent">
      <a-form-model :layout="layout" style="width:100%;" ref="ruleForm" :model="form">
        <a-col :sm="24" :md="12" :lg="8" :xl="6">
          <a-form-model-item label="用户名称" colon :labelCol='labelcol' :wrapperCol="wrappercol" prop="username">
            <a-input v-model="form.username" placeholder="请输入用户名称..." />
          </a-form-model-item>
        </a-col>
        <a-col :sm="24" :md="12" :lg="8" :xl="6">
          <a-form-model-item label="域名" colon :labelCol='labelcol' :wrapperCol="wrappercol" prop="domain">
            <a-input v-model="form.domain" placeholder="请输入域名..." />
          </a-form-model-item>
        </a-col>
        <a-col :sm="24" :md="12" :lg="8" :xl="6">
          <a-form-model-item label="分组名称" colon :labelCol='labelcol' :wrapperCol="wrappercol" prop="group_name">
            <a-input v-model="form.group_name" placeholder="请输入分组名称..."/>
          </a-form-model-item>
        </a-col>
        <a-col :sm="24" :md="12" :lg="8" :xl="6">
          <a-form-model-item label="客户邮箱" colon :labelCol='labelcol' :wrapperCol="wrappercol" prop="email">
            <a-input v-model="form.email" placeholder="请输入客户邮箱..."  type="textarea" :auto-size="{ minRows: 1, maxRows: 6 }"/>
          </a-form-model-item>
        </a-col>

          <a-col :sm="24" :md="12" :lg="8" :xl="6">
            <a-form-model-item label="订单状态" :labelCol='labelcol' :wrapperCol="wrappercol" prop="status">
              <a-select v-model="form.status" placeholder="请选择订单状态..." >
                <a-select-option value="">
                  全部
               </a-select-option>
                <a-select-option value="success">
                   成功
                </a-select-option>
                <a-select-option value="failed">
                   失败
                </a-select-option>
                <a-select-option value="unpaid">
                  未付款
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :sm="24" :md="12" :lg="8" :xl="6">
            <a-form-model-item label="支付方式" :labelCol='labelcol' :wrapperCol="wrappercol" prop="payment">
              <a-select v-model="form.payment" placeholder="请选择支付方式...">
                <a-select-option value="">
                  全部
               </a-select-option>
                <a-select-option value="paypal">
                  paypal
                </a-select-option>
                <a-select-option value="stripe">
                  stripe
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>

          <a-col :sm="24" :md="12" :lg="8" :xl="6"  >
            <a-form-model-item label="开始日期" :labelCol='labelcol' :wrapperCol="wrappercol" >
              <a-date-picker
              v-model="form.start_time"
              :show-time="{defaultValue: moment(new Date(), 'YYYY-MM-DD HH:mm:ss').endOf('day') }" format="YYYY-MM-DD HH:mm:ss" @change="onChangeStart" @ok="onOk"/>
            </a-form-model-item>
          </a-col>
          <a-col :sm="24" :md="12" :lg="8" :xl="6"  >
            <a-form-model-item label="结束日期" :labelCol='labelcol' :wrapperCol="wrappercol" >
              <a-date-picker
              v-model="form.end_time"
              format="YYYY-MM-DD HH:mm:ss"
              :show-time="{ defaultValue: moment(new Date(), 'YYYY-MM-DD HH:mm:ss').endOf('day') }" @change="onChangeEnd"/>
            </a-form-model-item>
          </a-col>

          <a-col :sm="24" :md="12" :lg="8" :xl="6" class="lyq_flex_grow">
            <a-form-model-item style="text-align:right;">
              
              <a-button type="primary" @click="search">
                搜索
              </a-button>
              <a-button @click="reset('ruleForm')">重置</a-button>
              <a-button type="primary" @click="exportData" :loading='isExport'>
                导出
              </a-button>
            </a-form-model-item>
          </a-col>
      </a-form-model>
    </div>
    <!-- <div class="mainContent" style="display: flex;justify-content: flex-end;">
      <a-button type="primary" @click="exportData" :loading='isExport'>
        导出
      </a-button>
    </div> -->
    <div class="mainContent" >

      <div class="tableHeader">
        <div class="title" style="word-break: keep-all;">查询表格</div>
        
        <div class="operate">
          <div class="ordersTotal">
            <span >总订单数：{{ordersTotal.total}}</span>
            <span>总成功单数：{{ordersTotal.success_num}}</span>
            <span>付款率：{{parseInt((ordersTotal.success_num/ordersTotal.total).toFixed(2)*100)||0}}%</span>
            <span>付款金额：{{ordersTotal.success_amount}}</span>
          </div>
          <a-tooltip placement="top">
            <template slot="title">
              <span>下载</span>
            </template>
            <a class="ant-dropdown-link" style="display:block;margin-right:10px;" @click="start" :disabled="!selectedRowKeys.length > 0">
              <a-icon type="vertical-align-bottom" />
            </a>
          </a-tooltip>

          <a-tooltip placement="top">
            <template slot="title">
              <span>表格大小</span>
            </template>
            <a-dropdown :trigger="['click']" style="margin-right:10px;">
              <a class="ant-dropdown-link" style="display:block">
                <a-icon type="column-height" />
              </a>
              <a-menu slot="overlay">
                <a-menu-item>
                  <a @click="tableSize='default'">默认</a>
                </a-menu-item>
                <a-menu-item>
                  <a @click="tableSize='middle'">中等</a>
                </a-menu-item>
                <a-menu-item>
                  <a @click="tableSize='small'">紧凑</a>
                </a-menu-item>
              </a-menu>
            </a-dropdown>
          </a-tooltip>

        </div>
      </div>

      <a-table
        :size="tableSize"
        :scroll="{ x: true }"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :columns="columns"
        :row-key="record => record.id"
        :data-source="data"
        :pagination="pagination"
        :loading="loading"
        bordered
        @change="handleTableChange"
      >
        <!-- 状态单元格 -->
        <template slot="status" slot-scope="data,record">
          <div style="display:flex;justify-content: space-evenly;align-items: center;">
            <a-tag :color="record.status=='success'?'green':record.status=='failed'?'red':'orange'">
                {{record.status|statusFilter}}
            </a-tag>

            <a-tooltip placement="top" v-if="record.is_duplicate">
              <template slot="title">
                <span>重复订单</span>
              </template>
              <span style="margin-left: 10px;border-radius: 50%;background: rgb(183 129 234);color: rgb(255 255 255);font-size: 12px;display: inline-block;height: 20px;width: 20px; line-height: 20px;" >
                复
              </span>
            </a-tooltip>
            
          </div>
        </template>
        <!-- 金额 -->
        <template slot="total" slot-scope="data,record">
          <div style='display: flex'>
            <span >{{record.currency}}：</span>
            <span>{{record.total}}</span>
          </div>
        </template>
        <!-- 金额 -->
        <template slot="email" slot-scope="data,record">
          <div style='display: inline-block'>
            <span class="spanItem"><span class='itemLabel'>邮箱</span>：<span class='itemContent'>{{record.email}}</span></span>
            <span class="spanItem"><span class='itemLabel'>电话</span>：<span class='itemContent'>{{record.phone}}</span></span>
          </div>
        </template>
        <!-- 地址 -->
        <template slot="state" slot-scope="data,record">
          <div style='display: inline-block'>
            <span class="spanItem"><span class='itemLabel'>州/省</span>：<span class='itemContent'>{{record.state}}</span></span>
            <span class="spanItem"><span class='itemLabel'>城市</span>：<span class='itemContent'>{{record.city}}</span></span>
            <span class="spanItem"><span class='itemLabel'>街道</span>：<span class='itemContent'>{{record.address}}</span></span>
            <span class="spanItem"><span class='itemLabel'>邮编</span>：<span class='itemContent'>{{record.zip_code}}</span></span>
          </div>
        </template>
        <!-- 操作单元格 -->
        <template slot="操作" slot-scope="data">

              <span>
                <a-button size="small" type="primary"  @click="onEdit(data.products)">商品详情</a-button>              
              </span>

        </template>
      </a-table>

      <a-modal v-model="productVisible" title="商品信息" @ok="productVisible=false" width="900px">
        <a-table
        :size="tableSize"
        :columns="productColumns"
        :row-key="record => record.id"
        :data-source="productList"
        :pagination='false'
        bordered
        >
          <!-- 金额 -->
          <template slot="product_url" slot-scope="data,record">
            <div style='display: flex'>
              <a :href="record.product_url" target="_blank">{{record.product_url}}</a>
            </div>
          </template>
        </a-table>

      </a-modal>
    </div>


  </div>
</template>
<script>
import {getOrderList} from "@/axios/order.js"
import {myMixin} from "@/static/js/mixin.js"
import moment from 'moment';
const productColumns = [//产品列描述
  {
    align:'center',
    title: '订单ID',
    dataIndex: 'order_id',
  },
  
  {
    align:'center',
    title: '商品名称',
    dataIndex: 'name',
  },
  {
    align:'center',
    title: '数量',
    dataIndex: 'quantity',
  },
  {
    align:'center',
    title: '产品链接',
    dataIndex: 'product_url',
    scopedSlots: { customRender: 'product_url', },
  },

];
const columns = [//列描述
  {
    align:'center',
    title: '客户名称',
    dataIndex: 'full_name',
  },
  
  {
    align:'center',
    title: '订单分组',
    dataIndex: 'group_name',
  },
  {
    align:'center',
    title: '下单网站',
    dataIndex: 'domain',
  },
  {
    align:'center',
    title: '下单时间',
    dataIndex: 'create_time',
  },

  {
    align:'center',
    title: '订单状态',
    dataIndex: 'status',
    scopedSlots: { customRender: 'status', },
  },
  {
    align:'center',
    title: '订单金额',
    dataIndex: 'total',
    scopedSlots: { customRender: 'total', },
  },
  {
    align:'center',
    title: '支付方式',
    dataIndex: 'payment',
  },
  {
    align:'center',
    title: '联系方式',
    dataIndex: 'email',
    scopedSlots: { customRender: 'email', },
  },
  {
    align:'center',
    title: '国家',
    dataIndex: 'country',
  },
  {
    align:'center',
    title: '地址',
    dataIndex: 'state',
    scopedSlots: { customRender: 'state', },
  },
  {
    align:'center',
    title: 'IP地址',
    dataIndex: 'ip_address',
  },

  {
    align:'center',
    title: '操作',
    // dataIndex: 'status',
    scopedSlots: { customRender: '操作', },
  },

];
export default {
  filters: {

    statusFilter(status) {
      const statusMap = {
        success:'成功',
        failed :'失败',
        unpaid:'未支付',
      }

      return statusMap[status]

    },
  },
  mixins: [myMixin],
  data() {

    return {
      productColumns,
      productList:[],
      productVisible:false,
      form: {
        domain:'',
        group_name: '',
        status: '',
        email:'',
        payment:'',
        username:'',
        start_time:'',
        end_time: '',
      },
      isExport:false,
      data: [],//数据
      selectedRowKeys: [],//选择的表格
      pagination: {
        showQuickJumper:true,//是否快速跳转某页
        showSizeChanger:true,//是否可以改变条数
        pageSizeOptions:['10', '20', '50', '100','200'],
        showTotal:(total, range) => `当前显示第${range[0]}-${range[1]}，总条数：${total} `
      },//分页器
      loading: false,//是否加载中
      btnLoading:false,
      columns,//列描述
      tableSize:'middle',//表格尺寸
      ordersTotal:{
        total:'',
        success_num:'',
        success_amount:'',
      },
    }
    
  },
  created(){
    let that=this
    this.form.start_time=this.moment(new Date()).startOf('day').format('YYYY-MM-DD HH:mm:ss')
    this.form.end_time=this.moment(new Date()).endOf('day').format('YYYY-MM-DD HH:mm:ss')

    this.fetch();

  },
  mounted() {

    
  },
  methods:{
      onEdit(data){
        this.productVisible=true;
        this.productList=data
      },
      onOk(){},
      moment,
      search(){//搜索
        this.form.email=this.form.email.split('\n').join(',')
        this.pagination.current =1;
        this.fetch({page:1,page_size:this.pagination.pageSize});
      },
      onChangeStart(date, dateString) {
        this.form.start_time=dateString;
      },
      onChangeEnd(date, dateString) {
        this.form.end_time=dateString;
      },
      reset(formName){
        this.$refs[formName].resetFields();
        this.pagination.current =1;
        this.fetch({page:1,page_size:this.pagination.pageSize});
      },
      onSelectChange(selectedRowKeys) {//选择表格
        this.selectedRowKeys = selectedRowKeys;
      },

      start() {//下载1
        console.log("导出",this.selectedRowKeys)
        // ajax request after empty completing
        setTimeout(() => {
          this.selectedRowKeys = [];
        }, 1000);
      },

      handleTableChange(pagination, filters, sorter) {
        //pagination:分页信息，filters：筛选信息，sorter：排序信息
        this.pagination = { ...pagination };
        console.log(this.pagination,pagination)
        this.fetch({
          page_size: pagination.pageSize,
          page: pagination.current,
          sortField: sorter.field,
          sortOrder: sorter.order,
          ...filters,
        });
      },

      async fetch (params = {}) {
        console.log(this.pagination,'this.pagination')
        this.loading = true;
        let data=await getOrderList({page_size: 10,page:1,...params,...this.form})
        this.loading = false;
        const pagination = { ...this.pagination };
        pagination.total = data.data.total;
        this.data = data.data.list;
        this.ordersTotal=data.data.statistics
        this.pagination = pagination;
      },
      // 导出
      async exportData(){
        this.isExport=true
        let data=await getOrderList({ ...this.form,export:'hello' })
        this.isExport=false
        window.location.href = data.data;

      }
    }
};
</script>
<style lang="scss" scoped>
@import "@/static/css/index.scss";
.ant-table td { white-space: nowrap; }
.ant-table td .greenBtn{background-color:#7ac141;border-color:#7ac141;}
.ant-table td .spanItems{display: inline-flex;}
.ant-table td .spanItem{display: flex;width:100%;}
.ant-table td .spanItem .itemLabel{text-align-last: justify;display: inline-block;width:46px;min-width:46px;margin-right: 4px;}
.ant-table td .spanItem .itemContent{max-width:150px;display: inline-block;text-align: left;}
.ordersTotal{font-weight:bold;margin:0 20px; display: flex;flex-wrap: wrap;width:100%;justify-content: flex-end;}
.ordersTotal span{width:150px;}
.ant-tag{margin-right:0}

::v-deep .ant-modal-body tr td:nth-child(2)
{
min-width:150px;
}
</style>